import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import Result from './Result';

const ResultPage = () => {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [averageScore, setAverageScore] = useState(0);

    const fetchData = async () => {
        try {
            const baseUrl = process.env.NODE_ENV === 'production' 
                ? '/api' 
                : 'http://localhost:5000/api'; // 開発用のAPIサーバー
            const response = await axios.get(`${baseUrl}/results/${id}`);
            const resultData = response.data;
            setData(resultData);
        } catch (error) {
            console.error('Error fetching result data:', error);
        }
    };

    useEffect(() => {
        fetchData(); // ページが読み込まれたときにデータを取得
    }, [id]);
    
    useEffect(() => {
        console.log(data); // データを確認
    }, [data]);

    const handleAverageScoreChange = (newScore) => {
        setAverageScore(newScore);
    };

    return (
        <>
            {data && (
                <Helmet>
                    <title>{`${data.title || '記事タイトル'} - 評価結果`}</title>
                    <meta name="description" content={`${data.summary || '記事の要約'}`} />
                    <meta property="og:title" content={`${data.title || '記事タイトル'} - ロジレビで評価されました`} />
                    <meta property="og:description" content={`ロジレビ評価は${averageScore}。詳細はこちらからご覧ください！`} />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:image" content={data.imageUrl || 'https://example.com/default-image.jpg'} />
                </Helmet>
            )}

            {data && (
                <Result
                    data={data}
                    onAverageScoreChange={handleAverageScoreChange}
                    averageScore={averageScore}
                    publisher={data.publisher || 'こ'}
                />
            )}
        </>
    );
};

export default ResultPage;
