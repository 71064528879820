import React, { useState, useEffect } from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler } from 'chart.js';
import axios from 'axios';
import '../styles/chart.scss';
import ShareButtons from './ShareButtons';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler);

const Chart = ({ onAnimationEnd, onAverageScoreChange, isScoreVisible, averageScore, evaluationData, resultId, publisher }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isShrunk, setIsShrunk] = useState(false);
    const [showShareButtons, setShowShareButtons] = useState(false);
    const [shouldApplyVisibleClass, setShouldApplyVisibleClass] = useState(false);
    const [calculatedAverage, setCalculatedAverage] = useState(null); 

    // チャートアニメーションとシェアボタン表示
    useEffect(() => {
        const isMobile = window.innerWidth <= 670;
        const expandDelay = isMobile ? 100 : 800;
        const shrinkDelay = isMobile ? 400 : 2500;
        const finalDelay = isMobile ? 300 : 1300;

        const expandTimer = setTimeout(() => {
            setIsExpanded(true);
        }, expandDelay);

        const shrinkTimer = setTimeout(() => {
            setIsShrunk(true);
        }, expandDelay + shrinkDelay);

        const finalTimer = setTimeout(() => {
            setShowShareButtons(true);
            setShouldApplyVisibleClass(true);
            if (onAnimationEnd) onAnimationEnd();
        }, expandDelay + shrinkDelay + finalDelay);

        return () => {
            clearTimeout(expandTimer);
            clearTimeout(shrinkTimer);
            clearTimeout(finalTimer);
        };
    }, [onAnimationEnd]);

    // 総合評価の計算とAPIへの送信
    useEffect(() => {
        if (evaluationData && onAverageScoreChange) {
            const scores = [
                parseFloat(evaluationData.credibility?.score) || 0,
                parseFloat(evaluationData.logic?.score) || 0,
                parseFloat(evaluationData.objectivity?.score) || 0,
                parseFloat(evaluationData.fairness?.score) || 0,
                parseFloat(evaluationData.diversity?.score) || 0
            ];
    
            // スコアが0でない項目だけをカウント
            const validScores = scores.filter(score => score > 0);
            const totalScore = validScores.reduce((acc, score) => acc + score, 0);
            let avgScore = (totalScore / validScores.length).toFixed(2); // 小数点第2位まで計算

            // 小数点第1位が.0で終わる場合は.0をつける
            if (avgScore.endsWith('.00')) {
                avgScore = parseInt(avgScore).toFixed(1); // 4.00 → 4.0
            } else if (avgScore.endsWith('0')) {
                avgScore = parseFloat(avgScore).toString(); // 4.50 → 4.5
            }

            setCalculatedAverage(avgScore); // 計算された総合評価を保存
            onAverageScoreChange(avgScore); // フロントで表示するためのハンドラ呼び出し

            // APIに総合評価を送信
            const sendAverageScoreToAPI = async (average) => {
                try {
                    await axios.post(`/results/${resultId}/average-score`, {
                        averageScore: average,
                    });
                    console.log('総合評価をデータベースに保存しました。');
                } catch (error) {
                    console.error('総合評価の送信中にエラーが発生しました。', error);
                }
            };

            sendAverageScoreToAPI(avgScore);
        }
    }, [evaluationData, onAverageScoreChange, resultId]);

    const labeledScores = [
        { label: '信憑性', description: '情報の正確さ', score: parseFloat(evaluationData?.credibility?.score) || 0 },
        { label: '論理性', description: '論理的な構成', score: parseFloat(evaluationData?.logic?.score) || 0 },
        { label: '客観性', description: '偏見のない情報', score: parseFloat(evaluationData?.objectivity?.score) || 0 },
        { label: '公平性', description: '公正な視点', score: parseFloat(evaluationData?.fairness?.score) || 0 },
        { label: '多角性', description: '多様な視点', score: parseFloat(evaluationData?.diversity?.score) || 0 },
    ];

    const data = {
        labels: labeledScores.map(item => `${item.label} ${item.score}/5`),
        datasets: [
            {
                label: '評価結果',
                data: labeledScores.map(item => item.score),
                backgroundColor: '#74BCD4',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
                pointStyle: 'line',
                pointRadius: 0,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            r: {
                angleLines: {
                    display: false,
                },
                ticks: {
                    display: false,
                    stepSize: 1,
                },
                suggestedMin: 0,
                suggestedMax: 5,
            },
        },
        plugins: {
            tooltip: {
                enabled: false,
            },
            legend: {
                display: false, 
            },
        },
    };

    return (
        <div className={`chart-container ${isExpanded ? 'expanded' : ''} ${isShrunk ? 'shrunk' : ''}`}>
            <div className={`average-score ${shouldApplyVisibleClass ? 'visible' : ''}`}>
                総合評価 : {averageScore || calculatedAverage}
            </div>
            <div className="chart-content">
                <Radar data={data} options={options} />
            </div>
            {showShareButtons && (
                <ShareButtons
                    url={window.location.href}
                    title={`${publisher}の記事をレビューしました！ロジレビ評価は${averageScore || calculatedAverage}。詳細はこちらからご覧ください！`}
                    averageScore={averageScore || calculatedAverage}
                    publisher={data.publisher || 'こ'} 
                />
            )}
        </div>
    );
};

export default Chart;
