import React, { useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import '../styles/style.scss';

const ErrorMessage = ({ visible }) => (
    <div className={`error-message ${visible ? 'visible' : ''}`}>
        <p>URLの形式が正しくありません。</p>
    </div>
);

const Main = () => {
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [isFormHidden, setIsFormHidden] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [url, setUrl] = useState('');
    
    const navigate = useNavigate();


    {/* ユーザーがURLを入力し評価ボタンを押したときに、サーバーにリクエストを送信する処理を定義 */}
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!url) {
            return;
        }

        try {
            new URL(url); 
        } catch (_) {
            setShowErrorMessage(true);
            setUrl('');
            setTimeout(() => setShowErrorMessage(false), 5000);
            return;
        }

        setShowErrorMessage(false);
        setIsLoading(true);
        setIsFormHidden(true);

        try {
            const response = await axios.post('/api/evaluate', { url });
        
            const { generatedUrl } = response.data;
        
            if (generatedUrl) {
                navigate(generatedUrl);
            }
        
        } catch (error) {
            console.error('Error fetching article data', error);
            setShowErrorMessage(true);
            setTimeout(() => setShowErrorMessage(false), 5000);
            setIsFormHidden(false);
        } finally {
            setIsLoading(false);
        }
        };
        

    const handleShowFormClick = () => {
        setIsFormHidden(false);
        setIsLoading(false);
        setUrl('');
    };

    return (
        <main>
            {/* Helmetを使用してトップページのタイトルを設定 */}
            <Helmet>
                <title>ロジレビ</title>
            </Helmet>

            {!isFormHidden && (
                <section className={isFormHidden ? 'hidden' : ''}>
                    <form id="evaluation-form" onSubmit={handleSubmit}>
                        <input
                            type="text"
                            id="article-url"
                            autoComplete="off"
                            value={url}
                            onChange={e => setUrl(e.target.value)}
                            placeholder="記事のURLを入力"
                        />
                        <button
                            id="evaluate-btn"
                            type="submit"
                        >
                            評価
                        </button>
                    </form>
                </section>
            )}

            <ErrorMessage visible={showErrorMessage} />

            {isLoading && (
                <section id="loading-indicator">
                    <div className="loader"></div>
                </section>
            )}

            {isFormHidden && (
                <div id="show-form-btn">
                    <button onClick={handleShowFormClick}>別の記事を評価</button>
                </div>
            )}
        </main>
    );
}

export default Main;
