import React, { useState, useEffect } from 'react';
import { MdMoreHoriz } from 'react-icons/md';
import { FiMail, FiLink } from 'react-icons/fi';
import threadsIcon from '../assets/images/threads.png';
import blueskyIcon from '../assets/images/bluesky.png';
import noteIcon from '../assets/images/note.png';
import { TwitterShareButton, FacebookShareButton, LinkedinShareButton, RedditShareButton } from 'react-share';
import twitterIcon from '../assets/images/x.png';
import facebookIcon from '../assets/images/facebook.png';
import linkedinIcon from '../assets/images/linkedin.png';
import redditIcon from '../assets/images/reddit.png';
import '../styles/sharebuttons.scss';

const ShareButtons = ({ url, title, averageScore, publisher }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [linkCopied, setLinkCopied] = useState(false);

    const handleMenuToggle = () => {
        if (showMenu) {
            setLinkCopied(false);
        }
        setShowMenu(prev => !prev);
    };

    const handleLinkCopy = () => {
        navigator.clipboard.writeText(url);
        setLinkCopied(true);
    };

    const handleClickOutside = (event) => {
        if (showMenu && !event.target.closest('.share-buttons')) {
            setShowMenu(false);
            setLinkCopied(false);
        }
    };

    const shareMessage = `${publisher}の記事をレビューしました！ロジレビ評価は${averageScore}。詳細はこちらからご覧ください！`;

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [showMenu]);

    const getShareUrl = (service, url, title, averageScore) => {
        const message = `${publisher}の記事をレビューしました！ロジレビ評価は${averageScore}。詳細はこちらからご覧ください！`;

        switch (service) {
            case 'threads':
                return `https://www.threads.net/share?url=${encodeURIComponent(url)}&title=${encodeURIComponent(message)}`;
            case 'bluesky':
                return `https://bsky.app/share?url=${encodeURIComponent(url)}&title=${encodeURIComponent(message)}`;
            case 'note':
                return `https://note.com/share?url=${encodeURIComponent(url)}&title=${encodeURIComponent(message)}`;
            default:
                return '#';
        }
    };

    const preventContextMenu = (event) => {
        event.preventDefault();
    };

    return (
        <div className="share-buttons">
            <TwitterShareButton url={url} title={shareMessage}>
                <img
                    src={twitterIcon}
                    alt="Twitter"
                    width={32}
                    height={32}
                    draggable="false"
                    onContextMenu={preventContextMenu}
                    style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', MsUserSelect: 'none' }}
                />
            </TwitterShareButton>

            <FacebookShareButton url={url} quote={shareMessage}>
                <img
                    src={facebookIcon}
                    alt="Facebook"
                    width={32}
                    height={32}
                    draggable="false"
                    onContextMenu={preventContextMenu}
                    style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', MsUserSelect: 'none' }}
                />
            </FacebookShareButton>

            <LinkedinShareButton url={url} title={shareMessage}>
                <img
                    src={linkedinIcon}
                    alt="LinkedIn"
                    width={32}
                    height={32}
                    draggable="false"
                    onContextMenu={preventContextMenu}
                    style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', MsUserSelect: 'none' }}
                />
            </LinkedinShareButton>

            <RedditShareButton url={url} title={shareMessage}>
                <img
                    src={redditIcon}
                    alt="Reddit"
                    width={32}
                    height={32}
                    draggable="false"
                    onContextMenu={preventContextMenu}
                    style={{ userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', MsUserSelect: 'none' }}
                />
            </RedditShareButton>

            <div className="menu-button" onClick={handleMenuToggle}>
                <MdMoreHoriz size={24} style={{ color: '#4a9ab2' }} />
            </div>

            {showMenu && (
                <div className="menu-options">
                    <button onClick={handleLinkCopy}>
                        <FiLink size={18} style={{ marginRight: '8px' }} />
                        {linkCopied ? 'リンクをコピーしました' : 'リンクをコピーする'}
                    </button>
                    <button onClick={() => window.location.href = `mailto:?subject=${publisher}の記事をレビューしました！&body=ロジレビ評価は${averageScore}。詳細はこちらからご覧ください！ ${url}`}>
                        <FiMail size={18} style={{ marginRight: '8px' }} />
                        メールでシェアする
                    </button>
                    <button onClick={() => window.open(getShareUrl('threads', url, title, averageScore), '_blank')}>
                        <img src={threadsIcon} alt="Threads" width={18} style={{ marginRight: '8px' }} />
                        Threadsでシェアする
                    </button>
                    <button onClick={() => window.open(getShareUrl('bluesky', url, title, averageScore), '_blank')}>
                        <img src={blueskyIcon} alt="Bluesky" width={18} style={{ marginRight: '10px' }} />
                        Blueskyでシェアする
                    </button>
                    <button onClick={() => window.open(getShareUrl('note', url, title, averageScore), '_blank')}>
                        <img src={noteIcon} alt="note" width={18} style={{ marginRight: '11px' }} />
                        noteでシェアする
                    </button>
                </div>
            )}
        </div>
    );
};

export default ShareButtons;
