import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Chart from './Chart';
import ArticleQuote from './ArticleQuote';
import '../styles/resultstyle.scss';

const Result = ({ data, onAverageScoreChange }) => {
    const [isChartAnimationComplete, setIsChartAnimationComplete] = useState(false);
    const [isQuotationVisible, setIsQuotationVisible] = useState(false);
    const [isDocumentVisible, setIsDocumentVisible] = useState(false);
    const [dynamicAverageScore, setDynamicAverageScore] = useState(null); 
    const navigate = useNavigate();

    const {
        original_url: url,
        title,
        image_url: imageUrl,
        summary,
        credibility_score,
        credibility_comment,
        logic_score,
        logic_comment,
        objectivity_score,
        objectivity_comment,
        fairness_score,
        fairness_comment,
        diversity_score,
        diversity_comment,
        overall_summary,
    } = data || {};

    // チャートアニメーションが完了したときに呼ばれる
    const handleChartAnimationEnd = () => {
        setIsChartAnimationComplete(true);
    };

    // 動的な総合評価を受け取る
    const handleAverageScoreChange = (calculatedAverageScore) => {
        setDynamicAverageScore(calculatedAverageScore); 
        if (onAverageScoreChange) {
            onAverageScoreChange(calculatedAverageScore); 
        }
    };

    useEffect(() => {
        if (isChartAnimationComplete) {
            setTimeout(() => {
                setIsQuotationVisible(true);
            }, 500);
        }
    }, [isChartAnimationComplete]);

    useEffect(() => {
        if (isQuotationVisible) {
            setTimeout(() => {
                setIsDocumentVisible(true);
            }, 1000);
        }
    }, [isQuotationVisible]);

    const handleEvaluateAnotherArticle = () => {
        navigate("/");
    };

    const evaluationData = {
        credibility: { score: credibility_score, comment: credibility_comment },
        logic: { score: logic_score, comment: logic_comment },
        objectivity: { score: objectivity_score, comment: objectivity_comment },
        fairness: { score: fairness_score, comment: fairness_comment },
        diversity: { score: diversity_score, comment: diversity_comment },
    };

    return (
        <main>
            <div id="show-form-btn">
                <button onClick={handleEvaluateAnotherArticle}>別の記事を評価</button>
            </div>

            <Chart
                isScoreVisible={true}
                onAnimationEnd={handleChartAnimationEnd}
                onAverageScoreChange={handleAverageScoreChange} 
                averageScore={dynamicAverageScore}
                evaluationData={evaluationData}
            />

            {isQuotationVisible && (
                <div className="quotation">
                    {title && imageUrl ? (
                        <ArticleQuote data={data} />
                    ) : (
                        <h2>記事の情報が取得できませんでした。</h2>
                    )}
                </div>
            )}

            {isDocumentVisible && (
                <div className="document">
                    <h3>記事の要約</h3>
                    <p>{summary || '要約が取得できませんでした。'}</p>

                    <h3>信憑性:&nbsp;{credibility_score || '評価不可'}/5</h3>
                    <p>{credibility_comment || 'コメントが取得できませんでした。'}</p>

                    <h3>論理性:&nbsp;{logic_score || '評価不可'}/5</h3>
                    <p>{logic_comment || 'コメントが取得できませんでした。'}</p>

                    <h3>客観性:&nbsp;{objectivity_score || '評価不可'}/5</h3>
                    <p>{objectivity_comment || 'コメントが取得できませんでした。'}</p>

                    <h3>公平性:&nbsp;{fairness_score || '評価不可'}/5</h3>
                    <p>{fairness_comment || 'コメントが取得できませんでした。'}</p>

                    <h3>多角性:&nbsp;{diversity_score || '評価不可'}/5</h3>
                    <p>{diversity_comment || 'コメントが取得できませんでした。'}</p>

                    {/* ここでは動的に生成された averageScore を使う */}
                    <h3>総合評価:&nbsp;{dynamicAverageScore || '評価不可'}</h3>
                    <p>{overall_summary || '総評が取得できませんでした。'}</p>
                </div>
            )}
        </main>
    );
};

export default Result;
