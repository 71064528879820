import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/footer.scss';

const Footer = () => {
    return (
        <footer>
            <div className="info">
                <ul>
                    <li><Link to="/about">このサイトについて</Link></li>
                    <li><Link to="/terms">利用規約</Link></li>
                </ul>
                <ul>
                    <li><Link to="/privacypolicy">プライバシーポリシー</Link></li>
                    <li><Link to="/feedback">フィードバック</Link></li>
                </ul>
                <ul>
                    <li><Link to="/faq">よくある質問（FAQ）</Link></li>
                    <li><Link to="/contact">お問い合わせ</Link></li>
                </ul>
                <ul>
                    <li><Link to="/updates">更新情報</Link></li>
                    <li><Link to="/support">サポート</Link></li>
                </ul>                
            </div>
            <p className="last">&copy;&nbsp;&nbsp;LogicReView βeta&nbsp;&nbsp;2024 </p>
        </footer>
    );
}

export default Footer;